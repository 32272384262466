import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PageTitle } from '@/shared/ui/PageTitle';
import { Button } from '@/shared/ui/Buttons';
import { Card } from '@/shared/ui/Card';
import { InputSearch, RadioButton } from '@/shared/ui/Inputs';
import classNames from 'classnames';
import Styles from '../GeneralStyles.module.scss';
import StylesInner from './KkmPage.module.scss';
import { TableView } from '@/shared/ui/Tables';
import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useSearchParams } from 'react-router-dom';
import { ButtonRefresh } from '@/shared/ui/Buttons';
import { KkmStore } from '@/entities/Kkm';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import { PageNoData } from '@/shared/ui/PageNoData';
import { SearchNoResult } from '@/shared/ui/SearchNoResult';
import { Paginate } from '@/shared/ui/Paginate';
import { Preload } from '@/shared/ui/Preload';
import { registrationStatus, typeDevices } from '@/shared/static/kkm';
import axios from 'axios';
import { IErrorMessage } from '@/shared/api/instanceAxios';
import { toast } from 'react-toastify';
import { formatDateUnix } from '@/shared/lib/formatDate';
import { IRegistrationStatus, kkmServices } from '@/shared/api/kkmServices';


interface IKkm {
	id: number;
	orgIinBin: string;
	registrationDate: string | null;
	kgdId: string;
	transaction: { fiscalNumber: 'string', id: number } | null;
	serialNum: string;
	transactionId: number;
	fiscalId: number;
	model: string;
	manufactureYear: string;
	registrationStatus: number;
	date: string;
	type: number;
	organisationId: number;
}

const columnHelper = createColumnHelper<IKkm>();

export const KkmPage: FC = observer(() => {

		const searchInput = useRef<HTMLInputElement>(null);
		const [searchParams, setSearchParams] = useSearchParams();
		const page = searchParams.get('page');
		const size = searchParams.get('size');
		const searchQueryParam = searchParams.get('search');
		const filterParam = searchParams.get('filter');
		const [currentFilter, setCurrentFilter] = useState<string>('');
		const [sorting, setSorting] = useState<SortingState>([]);
		const [kkmStatuses, setKkmStatuses] = useState<IRegistrationStatus[]>([]);

		useEffect(() => {
			kkmServices.getRegistrationStatus().then((res) => {
				setKkmStatuses(res);
			});
		}, []);

		useEffect(() => {
			if (searchInput.current) {
				searchInput.current.value = searchQueryParam || '';
				setCurrentFilter(filterParam || 'kkmId');
			}
		}, [searchInput]);

		const fetchData = useCallback(async () => {
				try {
					await KkmStore.fetchAll(filterParam ? { [filterParam]: searchQueryParam } : null, { page, size });
				} catch (e) {
					if (axios.isAxiosError(e)) {
						const response = await e.response?.data as IErrorMessage;
						toast.error(response?.details?.join(' '), {
							position: 'bottom-right',
						});
					}
				}
			}
			, [size, page, searchQueryParam, filterParam]);

		useEffect(() => {
			return () => {
				KkmStore.clear();
			};
		}, []);

		useEffect(() => {
			fetchData();
			if (Number(size) > 100) {
				setSearchParams(params => {
					params.set('size', '10');
					return params;
				});
			}
		}, [size, page, searchQueryParam, fetchData, setSearchParams]);

		const returnFocus = () => {
			const $el = searchInput.current as unknown as HTMLInputElement;
			$el.focus();
		};

		const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
			setSearchParams(param => {
				param.set('page', page.toString());
				return param;
			});
		};

		const onChangeSelectSize = (size: number) => {
			setSearchParams(param => {
				param.set('size', size.toString());
				return param;
			});
		};

		const handleClearSearchClick = async () => {
			if (searchInput.current) {
				searchInput.current.value = '';
			}
			returnFocus();
		};

		const handleReset = () => {
			if (searchInput.current) {
				searchInput.current.value = '';
			}
			setSearchParams({});
			fetchData();
		};
		const handleSearch = async () => {
			const searchQuery = searchInput.current?.value;
			setSearchParams({
				search: searchQuery || '',
				filter: currentFilter,
			});
		};

		const searchFilters = useMemo(() => [
			{
				id: '1',
				name: 'filter',
				value: 'kkmId',
				label: 'ID ККМ',
			},
			{
				id: '2',
				name: 'filter',
				value: 'registrationNumber',
				label: 'регистрационному номеру',
			},
			{
				id: '3',
				name: 'filter',
				value: 'kkmSerialNumber',
				label: 'серийному номеру',
			},

			{
				id: '4',
				name: 'filter',
				value: 'transactionId',
				label: 'ID транзакции',
			},
			{
				id: '5',
				name: 'filter',
				value: 'fiscalId',
				label: 'фискальному признаку',
			},
		], []);


		const columns = useMemo(() => [
			columnHelper.accessor('id', {
				header: 'ID ККМ',
				cell: ({ getValue }) => <Link to={`/kkm/${getValue()}`} className='table-link'>{getValue()}</Link>,
			}),
			columnHelper.accessor('orgIinBin', {
				header: 'ИИН/БИН организации',
				cell: ({ getValue, row }) => <Link to={`/taxpayers/${row.original.organisationId}`}
																					 className='table-link'>{getValue()}</Link>,

			}),
			columnHelper.accessor('fiscalId', {
				header: '№ фискальных данных',
				cell: ({ row }) => row.original.transaction?.fiscalNumber || '',
			}),
			columnHelper.accessor('kgdId', {
				header: 'РНМ',
			}),
			columnHelper.accessor('serialNum', {
				header: 'Серийный номер',
			}),
			columnHelper.accessor('manufactureYear', {
				header: 'Год выпуска',
			}),
			columnHelper.accessor('model', {
				header: 'Модель',
			}),
			columnHelper.accessor('registrationStatus', {
				header: 'Статус ККМ',
				cell: ({ getValue }) => kkmStatuses.find((item: any) => item.id === getValue())?.nameRu || '',
			}),
			columnHelper.accessor('registrationDate', {
				header: 'Дата регистрации',
				sortingFn: (a, b) => formatDateUnix(b.original.registrationDate) - formatDateUnix(a.original.registrationDate),
			}),
			columnHelper.accessor('type', {
				header: 'Тип',
				cell: ({ getValue }) => typeDevices[getValue()],
			}),
			columnHelper.accessor('transaction', {
				header: 'ID транзакций',
				cell: ({ getValue }) => getValue()?.id || '',
			}),
		], [kkmStatuses]);


		const tableInstance = useReactTable({
			columns,
			state: {
				sorting,
			},
			onSortingChange: setSorting,
			data: getSnapshot(KkmStore.kkmList),
			getCoreRowModel: getCoreRowModel(),
			getSortedRowModel: getSortedRowModel(),
		});

		return (
			<div>
				<Card className={classNames('pt-30 pr-32 pl-27 pb-23 mb-12')}>
					<PageTitle className={classNames('mb-30')}>
						Контрольно-кассовые машины
					</PageTitle>
					<div className={classNames(Styles.filterRow)}>
						{
							searchFilters.map((item, index) =>
								<label
									className={classNames(index !== searchFilters.length - 1 ? 'mr-45' : '', 'c_first_color')}
									key={item.id}>
									<RadioButton {...item} checked={item.value === currentFilter}
															 onChange={(e) => setCurrentFilter(e.target.value)} />
									<span>
                                    {item.label}
                                </span>
								</label>,
							)
						}
					</div>
					<div className='flex a-center mb-15 gap-20'>
						<InputSearch
							ref={searchInput}
							type={currentFilter === 'kkmId' ? 'number' : 'text'}
							clearSearch={handleClearSearchClick}
							placeholder='Введите необходимые данные для поиска'
							className={classNames(Styles.searchInput)}>
							<Button onClick={handleSearch} className={classNames(Styles.searchButton)}>
								Найти
							</Button>
						</InputSearch>
						<ButtonRefresh onClick={handleReset} />
					</div>
				</Card>

				{
					!searchInput.current?.value
						?
						<PageNoData message='Введите значение для поиска и найдите необходимую информацию о ККМ' /> :
						!KkmStore.kkmList.length && searchInput.current?.value ?
							<SearchNoResult searchQuery={searchInput.current?.value || ''} /> :
							<Scrollbars
								autoHeight
							>
								<Card>
									<TableView
										tableInstance={{ ...tableInstance }}
										className={classNames('table-border-radius', 'table-left', StylesInner.table)} />
								</Card>
							</Scrollbars>
				}
				{
					KkmStore.kkmList.length && searchInput?.current?.value ?
						<Paginate
							onChange={onPaginate}
							currentPage={KkmStore.currentPage}
							countPages={KkmStore.totalPages}
							size={size}
							onChangeSelectSize={onChangeSelectSize}
						/> : ''
				}
				{

					KkmStore.loading && <Preload />
				}

			</div>
		);
	},
);
