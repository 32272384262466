import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from '@/shared/ui/Card';
import { PageTitle } from '@/shared/ui/PageTitle';
import Styles from './TaxpayerDetailsPage.module.scss';
import classNames from 'classnames';
import { Button } from '@/shared/ui/Buttons';
import { DocumentIcon } from '@/assets/icons/DocumentIcon';
import { GeoIcon } from '@/assets/icons/GeoIcon';
import { ClockIcon } from '@/assets/icons/ClockIcon';
import { TrashIcon } from '@/assets/icons/TrashIcon';
import { BreadCrumb, BreadCrumbItem } from '@/shared/ui/BreadCumb';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import { TableView } from '@/shared/ui/Tables';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { TaxpayerInfo } from '@/components/Taxpayer/TaxpayerInfo';
import { Input } from '@/shared/ui/Inputs';
import { Autocomplete, TextField } from '@mui/material';
import { DeleteTaxpayerModal } from '@/modals/Taxpayers/DeleteTaxpayerModal';
import { makeStyles } from '@mui/styles';
import { UsersOrganizationIcon } from '@/assets/icons/UsersOrganizationIcon';
import theme from '@/shared/themes';
import { TaxpayersStore } from '@/entities/Taxpayer';
import { observer } from 'mobx-react-lite';
import { KkmStore } from '@/entities/Kkm';
import { getSnapshot } from 'mobx-state-tree';
import { Preload } from '@/shared/ui/Preload';
import { registrationStatus, typeDevices } from '@/shared/static/kkm';
import { TradePointStore } from '@/store/TradePointsStore';
import { toJS } from 'mobx';
import { ToastContainer } from 'react-toastify';
import { Paginate } from '@/shared/ui/Paginate';
import { TaxpayerBlockUnblock } from '@/features/Taxpayer';
import { BindServiceAccount } from '@/features/Taxpayer';
import { formatDateUnix } from '@/shared/lib/formatDate';
import { IRegistrationStatus, kkmServices } from '@/shared/api/kkmServices';

interface KKM {
	id: number;
	kgdId: string;
	registrationDate: string;
	model: string;
	serialNum: string;
	manufactureYear: string | null;
	type: number;
	registrationStatus: number;
}

interface ISection {
	id: number;
	icon: React.ReactElement;
	path: string;
	type: string;
	tip?: string;
}


const useStyle = makeStyles({
	rootInput: {
		padding: '0px !important',
		fontSize: '14px !important',
		'&:hover fieldset': {
			borderColor: `${theme.thirdColor} !important`,
		},
		'& fieldset': {
			border: `1px solid ${theme.thirdColor}`,

		},
	},
	focused: {
		borderColor: `${theme.mainColor} !important`,
	},
	input: {
		padding: '11px 14px !important',

	},
});
const columnHelper = createColumnHelper<KKM>();


export const TaxpayerDetailsPage: FC = observer(
	() => {
		const [sorting, setSorting] = useState<SortingState>([]);
		const [searchParams, setSearchParams] = useSearchParams();
		const size = searchParams.get('size');
		const page = searchParams.get('page');
		const { id } = useParams();
		useEffect(() => {
			TradePointStore.fetchTradePointsByOrgId(id as string);
			TaxpayersStore.fetchById(Number(id));
		}, [id]);
		const [kkmStatuses, setKkmStatuses] = useState<IRegistrationStatus[]>([]);

		useEffect(() => {
			kkmServices.getRegistrationStatus().then((res) => {
				setKkmStatuses(res);
			});
		}, []);
		const [selectedTradePoint, setSelectedTradePoint] = useState<null | number>(null);


		/*modals*/
		const [deleteTaxpayerModal, setDeleteTaxpayerModal] = useState<boolean>(false);
		/*****/

		const classes = useStyle();
		const location = useLocation();
		const navigate = useNavigate();
		useEffect(() => {
			return () => {
				TaxpayersStore.reset();
				KkmStore.clear();
			};
		}, []);

		const fetchData = useCallback(() => {

			KkmStore.fetchAll({
				retailPlaceId: selectedTradePoint || undefined,
				orgId: id,
			}, {
				page,
				size,
			});

		}, [page, size, selectedTradePoint, id]);


		useEffect(() => {
			fetchData();
		}, [page, size, fetchData, selectedTradePoint]);
		const handleSearch = async () => {
			fetchData();
		};


		const COLUMNS = useMemo(() => [
			columnHelper.accessor('id', {
				header: 'ИД ККМ',
				cell: ({ getValue }) => <Link className={classNames('table-link')}
																			to={`/kkm/${getValue()}`}>{getValue()}</Link>,
			}),
			columnHelper.accessor('kgdId', {
				header: 'ИД ККМ КГД',
			}),
			columnHelper.accessor('registrationDate', {
				header: 'Дата регистрации',
				sortingFn: (a, b) => formatDateUnix(b.original.registrationDate) - formatDateUnix(a.original.registrationDate),
			}),
			columnHelper.accessor('model', {
				header: 'Модель',
			}),
			columnHelper.accessor('serialNum', {
				header: 'Серийный номер',
			}),
			columnHelper.accessor('manufactureYear', {
				header: 'Год выпуска',
			}),
			columnHelper.accessor('type', {
				header: 'Тип ККМ',
				cell: ({ getValue }) => typeDevices[getValue()],
			}),
			columnHelper.accessor('registrationStatus', {
				header: 'Статус ККМ',
				cell: ({ getValue }) => kkmStatuses.find((item: any) => item.id === getValue())?.nameRu || '',
			}),
		], []);

		const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
			setSearchParams(param => {
				param.set('page', page.toString());
				return param;
			});
		};

		const onChangeSelectSize = (size: number) => {
			setSearchParams(param => {
				param.set('size', size.toString());
				return param;
			});
		};

		const sections = useMemo<ISection[]>(() =>
			[
				{
					id: 1,
					icon: <UsersOrganizationIcon />,
					path: `/taxpayers/${id}/usrs-of-org`,
					type: '',
					tip: 'Пользователи организации',
				},
				// {
				//     id: 2,
				//     icon: <DocumentIcon/>,
				//     path: '',
				//     type: '',
				//     tip: 'Документы'
				// },
				{
					id: 3,
					icon: <GeoIcon />,
					path: `/taxpayers/${id}/trade-points`,
					type: '',
					tip: 'Торговые точки',
				},
				{
					id: 4,
					icon: <ClockIcon />,
					path: `/taxpayers/${id}/history`,
					type: '',
					tip: 'История изменений',
				},
				{
					id: 6,
					icon: <TrashIcon />,
					path: '',
					type: 'delete',
					tip: 'Удалить',
				},

			], [id]);
		const tableInstance = useReactTable({
			columns: COLUMNS,
			data: getSnapshot(KkmStore.kkmList),
			state: {
				sorting,
			},
			onSortingChange: setSorting,
			getSortedRowModel: getSortedRowModel(),
			getCoreRowModel: getCoreRowModel(),
		});
		return (
			<>
				<Card>
					<div className={classNames('pt-30 pl-27 pr-26', Styles.wrapper)}>
						<BreadCrumb>
							<BreadCrumbItem to='/taxpayers'>Налогоплательщики</BreadCrumbItem>
							<BreadCrumbItem active
															to={location.pathname}>{TaxpayersStore.currentTaxpayer?.title}</BreadCrumbItem>
						</BreadCrumb>
						<PageTitle className={classNames('mb-24')}>
							{TaxpayersStore.currentTaxpayer?.title}
						</PageTitle>
						<TaxpayerInfo />
						<div className={classNames(Styles.toolPanel)}>
							{
								sections.map(({ id, icon, path, type, tip }) =>
									<Button
										key={id}
										isIcon
										tip={tip}
										onClick={() => {
											if (!type) navigate(path);
											if (type === 'delete') setDeleteTaxpayerModal(true);
										}}
										color={type === 'delete' ? 'danger' : 'simple'}
									>
										{icon}
									</Button>)
							}
						</div>
						<div className={classNames('flex j-between', Styles.row)}>
							<BindServiceAccount />
							<TaxpayerBlockUnblock />
						</div>
						{/*<div className={classNames('flex a-center j-between', Styles.row)}>*/}
						{/*    <div className='mr-15'>*/}
						{/*        Тарифы:*/}
						{/*    </div>*/}
						{/*    <Input className='mr-15 --fluid'/>*/}
						{/*    <Button className={Styles.button}>*/}
						{/*        Добавить по краткому описанию или ID*/}
						{/*    </Button>*/}
						{/*</div>*/}
						<div className={classNames(Styles.row)}>
							<h2 className={classNames('mb-20')}>
								ККМ
							</h2>
							<p className={classNames('mb-10 c_color_link')}>
								Найти ККМ по адресу:
							</p>
							<div className='flex mb-10'>
								<Autocomplete
									noOptionsText='По вашему запросу ничего не найдено'
									classes={{
										inputRoot: classes.rootInput,
										input: classes.input,
										focused: classes.focused,
									}}
									options={toJS(TradePointStore.retailPlaces)}
									onChange={(event: any, newValue: any) => {
										setSelectedTradePoint(newValue?.id);
									}}
									style={{ width: '100%' }}
									getOptionLabel={(option) => option.address?.fullAddress}
									renderOption={(props, option) => (
										<li {...props} className={classNames(Styles.listItem)}>
											{option.address?.fullAddress}
										</li>
									)}
									renderInput={(params) => (
										<TextField
											{...params} placeholder='Выберите из списка' />
									)}
								/>
								<Button onClick={handleSearch} className={classNames(Styles.searchButton, 'ml-10')}>
									Найти
								</Button>
							</div>

							<p>
								Всего найдено: {KkmStore.totalItems}
							</p>
						</div>

					</div>
					<Scrollbars autoHeight autoHeightMax={888}>
						<TableView tableInstance={{ ...tableInstance }}
											 className={classNames('table-head-sticky table-left ', Styles.table)} />
					</Scrollbars>

				</Card>
				{
					deleteTaxpayerModal &&
					<DeleteTaxpayerModal id={Number(id)} onClose={() => setDeleteTaxpayerModal(false)} />
				}
				{
					(TaxpayersStore.loading || KkmStore.loading || TradePointStore.loading) && <Preload />
				}
				<ToastContainer />
				{
					<Paginate
						onChange={onPaginate}
						currentPage={KkmStore.currentPage}
						countPages={KkmStore.totalPages}
						size={size}
						onChangeSelectSize={onChangeSelectSize}
					/>
				}

			</>

		);
	},
);
